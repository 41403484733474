<template>
    <div>
      <el-card v-if="dataLsit.length > 0">
        <p>资料列表</p>
        <el-table :data="dataLsit" border>
          <el-table-column prop="name" label="文件"></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="down(scope.row.dataurl)">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      
    </div>
</template>

<script>
export default {
  props:{
    examId:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      dataLsit:[]
    }
  },
  methods:{
    getExamData(){
      let data = {
        examid:this.examId
      }
      this.Api.Exam.getExamDataList(data).then(res => {
        if(res.retuCode == 200) {
          this.dataLsit = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    down(src){
       var elemIF = document.createElement('iframe')
      elemIF.src = src
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    }
  },
  watch:{
    examId:{
      deep:true,
      immediate:true,
      handler(val){
        if(val == 0) return false
        this.getExamData()
      }
    }
  }
}
</script>

<style scoped>

</style>