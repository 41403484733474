<template>
  <div class="text-l">
    <Coupon :couponList="couponList" />
  </div>
</template>

<script>
import Coupon from "@/components/pay/coupon";

export default {
  components:{
    Coupon
  },
  data(){
    return {
      couponList:[]
    }
  },
  created(){
    this.getCoupon()
  },
  methods:{
    getCoupon(){
      this.Api.Center.getCoupon({uid:this.$f.uid()}).then(res => {
        this.couponList = this.couponList.concat(res.data)
      })
    }
  }
}
</script>

<style scoped>

</style>