<template>
  <div>
    <el-dialog
      title="考试报名"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div>
        <el-steps :active="active" class="text-l">
          <el-step title="开始报名" icon="el-icon-s-home"></el-step>
          <el-step title="报名信息" icon="el-icon-s-claim"></el-step>
          <el-step title="缴费" icon="el-icon-s-finance"></el-step>
        </el-steps>
        <ExamInfo v-show="active == 2" :active="active" ref="examInfo" :dialogVisible="dialogVisible" :seleExamFirstId="seleExamFirstId" />
        <ExamComplete v-show="active == 3" ref="examComplete" :examId="examId" :dialogVisible="dialogVisible" :orderid="orderid" :pay="pay" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭窗口</el-button>
        <el-button type="primary" @click="saveInfo" v-show="active != 3"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExamInfo from "@/components/learningCenter/exam/examInfo";
import ExamComplete from "@/components/learningCenter/exam/examComplete"
export default {
  props:{
    seleExamFirstId:{   // 一级id
      type:Number,
      default:0
    }
  },
  components:{
    ExamInfo,ExamComplete
  },
  data() {
    return {
      dialogVisible: false,
      active:2,
      examId:0,
      info:'',
      orderid:0,
      pay:false
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    saveInfo(){
      if(this.active == 1) {
        this.active = 2
      } else if(this.active == 2) {
        this.$refs.examInfo.$refs.ruleForm.validate(valid => {
          if(valid) {
            let data = this.$refs.examInfo.examInfo
            data.userId = this.$f.uid()
            data.examFirstId = this.seleExamFirstId
            this.examId = data.examid
            this.Api.Exam.addExamInfo(data).then(res => {
              if(res.retuCode == 200) {
                this.active = 3
                this.$refs.examComplete.orderId = res.data.id
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      } else {
        console.log('3')
      }
    },
    getDetial(data){
      this.$nextTick(() => {
        this.$refs.examInfo.newInfo = data
      })
    }
  },
  watch:{
    dialogVisible(val) {
      if(!val){
        this.examId = 0
        this.pay = false
        this.orderid = 0
        this.active = 2
        this.$parent.getUserExamList() // 重新查询报名列表
      }
    }
  }
};
</script>

<style scoped>
</style>