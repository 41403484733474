<template>
    <div>
      <!-- 支付成功后显示 -->
      <div v-show="payType">
        <el-button type="text" style="color:black" class="f-s-24">
          <i class="el-icon-circle-check" style="color:green"></i>
          缴费成功
        </el-button>
        <ExamGroup :examId="examId" class="m-t-15" />
        <ExamData :examId="examId" class="m-t-15" />
      </div>
      <!-- 支付模块 -->
      <div v-show="!payType">
        <TitleText text="扫码支付(支付过程中如遇到问题请联系老师)" />
        <Mode ref="mode" @editPayRadio="editPayRadio" />
        <WxPay ref="wxpay" v-show="payRadio == '1'" :icon="wxPayPic" />
        <AliPay ref="alipay" v-show="payRadio == '2'" :icon="aliPayPic" />
      </div>
    </div>
</template>

<script>
import ExamGroup from "@/components/learningCenter/exam/examGroup"
import ExamData from "@/components/learningCenter/exam/examData"
import Mode from "@/components/pay/mode";
import TitleText from "@/components/pay/titleText";
export default {
  props:{
    examId:{
      type:Number,
    },
    dialogVisible:{
      type:Boolean,
      default:false
    },
    orderid:{
      type:Number
    },
    pay:{
      type:Boolean
    }
  },
  components:{
    ExamGroup,ExamData,TitleText,Mode
  },
  data(){
    return{
      wxPayPic: require("@/assets/img/pay/wxPayPic.jpeg"),
      aliPayPic: require("@/assets/img/pay/ali.jpeg"),
      payRadio:'1',
      payType:false,
      orderId:'',
      wxUrl:"",
      aliUrl:"",
      lookPay:'',
      judge:1
    }
  },
  methods:{
    // 修改父组件选中的支付方式
    editPayRadio(val) {
      this.payRadio = val;
    },
    getPayUrl(){
      if(this.judge!=1){
        return;
      }
      this.judge=0;
      let data = {
        type : this.payRadio * 1,
        id : this.orderId,
      }
      this.Api.Exam.payExamOrder(data).then(res=>{
        if(res.retuCode == 200) {
          if (this.$refs.mode.radio == "1") {
            this.wxUrl = res.data.qRcode;
            this.$refs.wxpay.wxPayUrl = res.data.qRcode;
          } else {
            this.aliUrl = res.data.qRcode;
            this.$refs.alipay.alpayUrl = res.data.qRcode;
          }

          this.judge=1;
        }
      })
    },
    getPatType(){
      let data = {
        id : this.orderId
      }
      this.Api.Exam.getPatType(data).then(res => {
        if(res.retuCode == 200) {
          if(res.data.payStater == 1) {
            this.payType = false
            return
          } else if(res.data.payStater == 2) {
            clearInterval(this.lookPay)
            this.payType = !this.payType
          }
        } else {
          this.getPatType()
        }
      })
    }
  },
  watch:{
    pay:{
      deep:true,
      immediate:true,
      handler(val) {
        this.payType = val
      }
    },
    orderId:{
      deep:true,
      immediate:true,
      handler(val) {
        if(val == '') return false
        this.getPayUrl()
        this.lookPay = setInterval(() => {
          this.getPatType()
        },2000)
      }
    },
    orderid:{
      deep:true,
      immediate:true,
      handler(val) {
        if(val != 0)
        this.orderId = val
      }
    },
    payRadio(){
      this.wxUrl='';
      this.aliUrl='';
      this.$refs.wxpay.wxPayUrl = '';
      this.$refs.alipay.alpayUrl = '';
      // if(val == 1 && this.wxUrl != '') {
      //   return
      // } else if(val == 2 && this.aliUrl != '') {
      //   return
      // }
      this.getPayUrl()
    },
    dialogVisible:{
      deep:true,
      immediate:true,
      handler(val){
        if(!val) this.orderId = ''
        if(!val && this.lookPay != '') {
          clearInterval(this.lookPay)
        } else {
          this.getPayUrl()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>