<template>
    <div>
      <el-table :data="agreementList" border>
        <el-table-column label="协议名称" prop="name"></el-table-column>
        <el-table-column label="签订日期" prop="signTime" width="180"></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <p>{{agreStatus(scope.row)}}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="look(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Notice :title="title" ref="notice" :cid="cid" @getAgreementLsit="getAgreementLsit" :id="id" />
    </div>
</template>

<script>
import Notice from "@/components/learningCenter/class/item/notice";

export default {
  components: {
    Notice
  },
  created(){
    this.getAgreementLsit()
  },
  data(){
    return {
      agreementList:[],
      title:"查看协议",
      cid:0
    }
  },
  methods:{
    getAgreementLsit(){
      let data = {
        uid : this.$f.uid()
      }
      this.Api.Agreement.agreementList(data).then(res => {
        if(res.status == 200) {
          this.agreementList = res.data
        }
      })
    },
    look(row){
      if(row.signstate == 2) {
        this.title = "签署协议"
        this.cid = row.cid
        this.$refs.notice.dialogVisible = true
      } else {
        this.id = row.cid
        this.cid = row.agreementid
        this.$refs.notice.dialogVisible = true
      }
      
    },
    agreStatus(row){
      if(row.signstate == 1) {
        return "未签署"
      } else if(row.signstate == 2){
        return "同意未签署信息"
      } else {
        return "签署成功"
      }
    }
  }
}
</script>

<style scoped>

</style>