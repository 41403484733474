<template>
  <div>
    <template v-if="list.length > 0">
      <el-table :data="list" style="width: 100%" stripe border>
        <el-table-column label="课程名称" width="500">
          <template slot-scope="scope">
            {{scope.row.courseTitle}}
          </template>
        </el-table-column>
        <el-table-column prop="discountPrice" label="价格">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{Type(scope.row.state)}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="lookDetial(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div v-else @click="goClass">
      <el-image :src="noOrder" style="width:50%"></el-image>
      <el-button type="warning" style="display:block;margin-left:45%">去选课</el-button>
    </div>
    <OrderDetial :open="open" @editOpen="editOpen" @paySuc="paySuc" :orderData="orderData" />
  </div>
</template>
<script>
import OrderDetial from '@/components/learningCenter/order/item/orderDetial';
export default {
  props:{
    list:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  components:{
    OrderDetial
  },
  data() {
    return {
      open:false,
      orderData:{},
      noOrder:require("@/assets/img/order/noOrder.png")

    };
  },
  methods:{
    goClass(){
      this.$router.push("/curriculum")
    },
    paySuc(){
      this.$emit("paySuc")
    },
    lookDetial(item){
      let data = {
        orderNumber:item.orderNumber
      }
      this.Api.Center.getOrderDetial(data).then(res => {
        this.orderData = res.data
      })
      this.open = true
    },
    editOpen(val){
      this.open = val
    },
    Type(type){
      if(type == "1"){
        return '待付款'
      }else if(type == "2"){
        return "已付款"
      }else if (type == "3"){
        return "已失效"
      }
    }
  }
};
</script>

<style scoped>
</style>