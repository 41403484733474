<template>
  <div>
    <el-descriptions
      class="margin-top"
      title="个人信息"
      :column="3"
      border
    >
      <template slot="extra">
        <!-- <el-button type="primary" size="small">操作</el-button> -->
      </template>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          姓名
        </template>
        {{examInfo.name}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-male"></i>
          性别
        </template>
        {{examInfo.sex == "1" ? "男":"女"}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-place"></i>
          身份证
        </template>
        {{$f.formatIDcard(examInfo.quserid)}}
      </el-descriptions-item>
       <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          学历
        </template>
        {{$f.educationlist()[examInfo.education*1].name}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        {{examInfo.phone}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          居住地
        </template>
        {{examInfo.province}}-{{examInfo.city}}-{{examInfo.area}}
      </el-descriptions-item>
     
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          联系地址
        </template>
        {{examInfo.address}}
      </el-descriptions-item>
    
    </el-descriptions>
  </div>
</template>

<script>
export default {
  data() {
    return {
      examInfo:{
        name:"",
        userID:this.$f.uid(),
        sex:"1",
        phone:'',
        education:'',
        occupation:'',
        province:'',  // 省
        city:"",  // 市
        area:"",  // 区
        address:"", // 详细地址
        quserid:''
      },
    };
  },
  created(){
    this.getUserInfo()
  },
  methods:{
    // 获取用户 信息
    getUserInfo(){
      let data = {
        userId:this.$f.uid()
      }
      this.Api.Exam.getUserInfo(data).then(res => {
        if(res.retuCode == 200) {
          this.examInfo = res.data
        }
      })
    }
  }
};
</script>

<style scoped>
</style>