<template>
  <div class="classTab">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="0">
        <ClassItem :list = "allClassList" />
      </el-tab-pane>
      <el-tab-pane label="免费课" name="1">
        <ClassItem :list = "freeClassList" />
      </el-tab-pane>
      <el-tab-pane label="付费课" name="2">
        <ClassItem :list = "paidClassList" />
      </el-tab-pane>
    </el-tabs>
    <div class="text-l" hidden>
      <el-page-header @back="goBack" content="详情页面"> </el-page-header>
      <el-card class="m-t-10">
        <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              <span class="xz-color-bl">录播</span>
              <i class="el-icon-video-play m-l-10"></i>
              <span>零基础入门BIM必修课</span>
            </template>
            <div class="f-s-16 b-b-1 cu classItem">
              <span>1.零基础必学的BIM入门介绍</span>
              <span class="f-l-r time">
                <i class="el-icon-video-play m-l-10"></i>
                00:50:11
              </span>
              <div class="f-l-r player">
                <el-button type="warning" size="mini">
                <i class="el-icon-video-play"></i>
                  播放
                </el-button>
              </div>
            </div>
            
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
  </div>
</template>

<script>
import ClassItem from "@/components/learningCenter/class/item/classItem";
export default {
  components: {
    ClassItem,
  },
  data() {
    return {
      activeName: "0",
      allClassList : [],  // 存放全部课程
      freeClassList: [], // 免费课程
      paidClassList: [], // 付费课程
    };
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.index
    },
    goBack(){
      console.log('返回')
    },
    getClassList(){
      let data = {
        loginname:JSON.parse(localStorage.getItem("user")).loginname,
        free:this.activeName == "1" ? true : "",
        charge:this.activeName == "2" ? true : "",
      }
      this.Api.Center.getClassList(data).then(res => {
        if(res.msg == '用户不存在，请重新登录'){
          this.$f.removeLocal([
            "token",
            "userInfo",
            "setToken",
            "loginPhone",
            "user",
            "uid",
          ])
          this.$store.commit("overall/editLoginType",false)
          this.$store.commit("overall/editUser",{})
          this.$alert(res.msg,'提示',{
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.$router.replace({path:"/"})
            location.reload();
          })
          return false
        }
        if(this.activeName == "0"){
          this.allClassList = res.data
        }else if(this.activeName == "1"){
          this.freeClassList = res.data
        }else {
          this.paidClassList = res.data
        }
      })
    }
  },
  watch:{
    activeName:{
      deep:true,
      immediate:true,
      handler(val){
        if(val == "0" && this.allClassList.length > 0) return false
        else if(val == "1" && this.freeClassList.length > 0) return false
        else if(val == "2" && this.paidClassList.length > 0) return false
        this.getClassList()
      }
    }
  }
};
</script>

<style>
.classTab .el-tabs__item {
  height: 50px !important;
}
.classItem {
  height: 40px;
  line-height: 40px;
}
.classItem:hover .player{
  display: inline-block;
}
.classItem:hover {
  background-color: #f2f2f2;
}
.classItem:hover .time{
  display: none;
}
.player {
  display: none;
}
</style>