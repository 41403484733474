<template>
  <div >
    <div >
    <div>
      <el-button type="success" size="mini" class="f-l-l m-b-10" @click="addExamInfo">我要报名</el-button>
      <!-- <el-button type="text" icon="el-icon-question" class="f-l-r">查看报名流程</el-button> -->
    </div>
    <el-table :data="examList" border class="m-t-10">
      <el-table-column label="分类" prop="examFirstName"></el-table-column>
      <el-table-column label="考试名称" prop="name"></el-table-column>
      <!-- <el-table-column label="考试期数"></el-table-column> -->
      <!-- <el-table-column label="成绩" prop="fraction"></el-table-column> -->
      <!-- <el-table-column label="是否合格">
        <template slot-scope="scope">
          <el-button type="success" v-if="scope.row.qualified == '1'" size="mini">合格</el-button>
          <el-button type="danger" v-else size="mini">不合格</el-button>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="快递单号" prop="express"></el-table-column> -->
      <!-- <el-table-column label="报名状态"></el-table-column> -->
      <!-- <el-table-column label="考试入口"></el-table-column> -->
      <el-table-column label="报名时间" prop="addDate" width="170"></el-table-column>
      <el-table-column label="支付状态" prop="payStater" width="180">
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.payStater == 1" style="color:red">未支付</el-button>
          <el-button type="text" v-if="scope.row.payStater == 2" style="color:green">已支付</el-button>
          <el-button type="text" v-if="scope.row.payStater == 3" style="color:red">已退费</el-button>
          <el-button type="success" size="mini" v-if="scope.row.payStater == 1" @click="goPay(scope.row)">去支付</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div v-if="scope.row.payStater != 3">
            <el-button type="text" @click="getUserExamInfoById(scope.row)" v-if="scope.row.payStater == 1">编辑</el-button>
            <el-button type="text" @click="lookTicket(scope.row)" v-if="scope.row.payStater != 1 && scope.row.examFirstId == 1">查看准考证</el-button>
            <el-button type="text" @click="lookData(scope.row)" v-if="scope.row.payStater != 1">查看资料</el-button>
          </div>
          <el-button type="text" v-else>已退费</el-button>
        </template>
      </el-table-column>
    </el-table>
    <AddExam ref="addExam" :seleExamFirstId="seleExamFirstId" />
    <Ticket ref="ticket" />
    <SeleExamFirst ref="seleExamFirst" :examFirstList="examFirstList" @editSeleExamFirstId="editSeleExamFirstId" />
    <NoPayUser ref="noPayUser" />
    </div>
  </div>
</template>

<script>
import AddExam from "@/components/learningCenter/exam/addExam";
import Ticket from "@/components/learningCenter/exam/ticket"
import SeleExamFirst from "@/components/learningCenter/exam/seleExamFirst"
import NoPayUser from "@/components/learningCenter/exam/noPayUser"
export default {
  components:{
    AddExam,Ticket,SeleExamFirst,NoPayUser
  },
  data(){
    return {
      examList:[],
      examFirstList:[],
      seleExamFirstId:0
    }
  },
  created(){
    this.getUserExamList()
    this.getExamFirstList()
  },
  methods:{
    // 修改选定的一级考试id
    editSeleExamFirstId(id){
      this.seleExamFirstId = id
      for (let i = 0;i<this.examFirstList.length;i++) {
       if(this.examFirstList[i].id == this.seleExamFirstId) {
         if(this.examFirstList[i].ordertype == '0') {   // 全部学员可以
          this.$refs.addExam.dialogVisible = true
         } else {  // 只有付费学员才可以报名
           this.getUserPayType()  // 判断是否为付费学员
         }
         break
       }
      }
    },
    // 判断用户是否为付费学员
    getUserPayType(){
      let data = {
        uid : this.$f.uid()
      }
      this.Api.User.getUserPayType(data).then(res => {
        if(res.retuCode == 200) {
          if(res.data) {
            // 去填写信息
            this.$refs.addExam.dialogVisible = true
          } else {
            // 去购买付费课程
            this.$refs.noPayUser.dialogVisible = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取一级考试列表
    getExamFirstList(){
      this.Api.Exam.getExamFirstList().then(res => {
        if(res.retuCode == 200) {
          this.examFirstList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 打开新增考试对话框
    addExamInfo(){
      if(this.examFirstList.length == 1) {
        this.seleExamFirstId = this.examFirstList[0].id
        this.$refs.addExam.dialogVisible = true
      } else if(this.examFirstList.length == 0) {
        this.$message.warning("暂未开放报名")
      } else if(this.examFirstList.length > 1) {
        this.$refs.seleExamFirst.dialogVisible = true
      }
    },
    // 查看资料
    lookData(row){
      this.$refs.addExam.examId = row.examid
      this.$refs.addExam.dialogVisible = true
      this.$refs.addExam.active = 3
      this.$refs.addExam.pay = true
    },
    // 去支付
    goPay(row){
      this.$refs.addExam.pay = false
      this.$refs.addExam.orderid = row.id
      this.$refs.addExam.examId = row.examid
      this.$refs.addExam.dialogVisible = true
      this.$refs.addExam.active = 3
    },
    // 查看准考证号
    lookTicket(row){
      let data = {
        id : row.id
      }
      this.Api.Exam.getUserExamInfoById(data).then(res => {
        if(res.retuCode == 200) {
          this.$refs.ticket.dialogVisible = true
          if(res.data.qualified == '0'){
            res.data.quaName = '否'
          } else if(res.data.qualified == '1'){
            res.data.quaName = '是'
          } else {
            res.data.quaName = '暂无信息'
          }
          this.$refs.ticket.ticketInfo = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getUserExamList(){
      let data = {
        userId:this.$f.uid()
      }
      this.Api.Exam.getUserExamList(data).then(res => {
        if(res.retuCode == 200) {
          this.examList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getUserExamInfoById(row){
      this.seleExamFirstId = row.examFirstId
      let data = {
        id : row.id
      }
      this.Api.Exam.getUserExamInfoById(data).then(res => {
        if(res.retuCode == 200) {
          this.$refs.addExam.dialogVisible = true
          this.$refs.addExam.active = 2
          this.$refs.addExam.getDetial(res.data)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>