<template>
  <div class="orderTab">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部订单" name="0">
        <OrderItem :list = "allOrderList" @paySuc="paySuc" />
      </el-tab-pane>
      <el-tab-pane label="待付款" name="1">
        <OrderItem :list = "unpaidOrderList" @paySuc="paySuc" />
      </el-tab-pane>
      <el-tab-pane label="已付款" name="2">
        <OrderItem :list = "paidOrderList" @paySuc="paySuc" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OrderItem from "@/components/learningCenter/order/item/orderItem";
export default {
  components: {
    OrderItem,
  },
  data() {
    return {
      activeName: "0",
      allOrderList:[], // 存放全部订单
      unpaidOrderList:[],  // 未支付订单
      paidOrderList:[], // 已付款订单
    };
  },
  methods: {
    paySuc(){
      this.getOrderList()
    },
    handleClick(tab) {
      this.activeName = tab.index
    },  
    getOrderList(){
      let that = this
      let data = {
        state:this.activeName == "0" ? null : this.activeName,
        loginname:JSON.parse(localStorage.getItem("user")).loginname
      }
      this.Api.Center.getOrderList(data).then(res => {
        if(that.activeName == "0"){
          that.allOrderList = res.data
        }else if(that.activeName == "1"){
          that.unpaidOrderList = res.data
        }else {
          that.paidOrderList = res.data
        }
      })
    }
  },
  watch:{
    activeName:{
      deep:true,
      immediate:true,
      handler(val){
        if(val == "0" && this.allOrderList.length > 0) return
        else if(val == "1" && this.unpaidOrderList.length > 0) return
        else if(val == "2" && this.paidOrderList.length > 0) return
        this.getOrderList()
      }
    }
  }
};
</script>

<style>
.orderTab .el-tabs__item {
  height: 50px !important;
}
</style>