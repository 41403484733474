<template>
    <div class="info">
      <el-form :model="examInfo" label-width="80px" style="width:400px;margin:auto" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="examInfo.name" placeholder="请输入姓名" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="拼音" v-if="seleExamFirstId == '1'">
          <el-input v-model="examInfo.englishName" placeholder="请输入姓名拼音(如：xiaozhu)" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" v-if="seleExamFirstId == '1'">
          <el-input v-model="examInfo.email" placeholder="请输入邮箱" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="examInfo.sex" label="1">男</el-radio>
          <el-radio v-model="examInfo.sex" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="身份证号" prop="quserid">
          <el-input v-model="examInfo.quserid" placeholder="请输入身份证号" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-select v-model="examInfo.education" placeholder="请输入学历" size="mini" class="f-l-l" style="width:100%">
            <el-option :label="item.name" :value="item.id" v-for="(item,i) in $f.educationlist()" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职业岗位" prop="occupation">
          <el-input v-model="examInfo.occupation" placeholder="请输入职业岗位" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="examInfo.phone" placeholder="请输入手机号" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址" v-if="seleExamFirstId == '1'">
          <v-distpicker @province="provinceChange" @city="cityChange" @area="areaChange" :province="examInfo.province" :city="examInfo.city" :area="examInfo.area"></v-distpicker>
          <el-input v-model="examInfo.address" placeholder="请输入详细地址" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="考试城市" v-if="seleExamFirstId == '2'">
          <el-input v-model="examInfo.examAddress" placeholder="请输入考试城市(选填)"></el-input>
        </el-form-item>
        <el-form-item label="考点名称" v-if="seleExamFirstId == '2'">
          <el-input v-model="examInfo.addressName" placeholder="请输入考点名称(选填)"></el-input>
        </el-form-item>
        <el-form-item label="考试科目" prop="examid">
          <el-select :disabled="examInfo.id != undefined " v-model="examInfo.examid" placeholder="请选择考试科目" size="mini" class="f-l-l" style="width:100%" clearable>
            <el-option :label="item.name + '---报名费:' + item.money + '元'" :value="item.id" v-for="(item,i) in examList" :key="i"></el-option>
          </el-select>
          <el-button type="text" size="mini" v-if="examInfo.examid != '' && examDiscountList != undefined && examDiscountList.length <= 0" >总计需要付款:{{examMoney - discountMoney}}元</el-button>
        </el-form-item>
        <el-form-item label="报名优惠" v-if="examDiscountList != undefined && examDiscountList.length > 0">
         <el-select :disabled="examInfo.id != undefined " v-model="examInfo.classid" @change="changerList" placeholder="请选择优惠" size="mini" class="f-l-l" style="width:100%">
           <el-option :label="item.name + '-优惠:' +item.discount + '元'"  :value="item.classid" v-for="(item,i) in examDiscountList" :key="i"></el-option>
         </el-select>
         <el-button type="text" size="mini">总计需要付款:{{examMoney - discountMoney}}元</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
export default {
  props:{
    active:{
      type:Number,
      default:1
    },
    dialogVisible:{
      type:Boolean,
      default:false
    },
    seleExamFirstId:{  // 一级id
      type:Number,
      default:0
    }
  },
  data(){
    return {
      examInfo:{
        name:"",
        englishName:'',
        userID:this.$f.uid(),
        sex:"1",
        phone:'',
        education:'',
        occupation:'',
        province:'',  // 省
        city:"",  // 市
        area:"",  // 区
        address:"", // 详细地址
        examid:'',
        quserid:'',
        classid:'',
        examAddress:"", // 考试城市
        addressName:"", // 考点名称
        email:"", // 邮箱
      },
      rules:{
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        quserid:[
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ],
        education:[
          { required: true, message: '请输入学历', trigger: 'blur' },
        ],
        occupation:[
          { required: true, message: '请输入职业', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        address:[
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        examid:[
          { required: true, message: '请选择考试科目', trigger: 'blur' },
        ]
      },
      examList:[],
      examMoney:0,
      examDiscountList:[],
      discountMoney:0,
      newInfo:'',
    }
  },
  methods:{
    //三个选择框的值改变事件，data参数：返回选择框改变时候的值，是一个对象
		provinceChange(data){//省
			this.examInfo.province = data.value
		},
		cityChange(data){//市
			this.examInfo.city = data.value
		},
		areaChange(data){//区、县
			this.examInfo.area = data.value
		},
    // 获取考试列表
    getExamList(){
      let data = {
        belone : this.seleExamFirstId
      }
      this.Api.Exam.getExamList(data).then(res => {
        if(res.retuCode == 200) {
          this.examList = res.data
          if(this.newInfo != '') {
            this.examInfo = this.newInfo
          }
          if(this.examInfo.examid != ''){
            this.examList.filter(item => {
              if(item.id == this.examInfo.examid) {
                this.examMoney = item.money
              }
            })
          }
          
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取用户 信息
    getUserInfo(){
      let data = {
        userId:this.$f.uid()
      }
      this.Api.Exam.getUserInfo(data).then(res => {
        if(res.retuCode == 200) {
          res.data.examid = ""
          this.examInfo = res.data
          if(this.newInfo != '') {
            this.examInfo = this.newInfo
          }
        }
      })
    },
    // 获取用户当前课程 进行优惠计算
    getUSerDiscount(){
      let data = {
        userid : this.$f.uid(),
        examId : this.examInfo.examid
      }
      this.examDiscountList = []
      this.examInfo.classid = ""
      this.discountMoney = 0
      this.Api.Exam.getUserDiscount(data).then(res => {
        if(res.retuCode == 200) {
          this.examDiscountList = res.data
          if(this.examDiscountList != undefined && this.examDiscountList.length != undefined && this.examDiscountList.length > 0) {
            this.examDiscountList.forEach(item => {
              if(item.discount > this.discountMoney) {
                this.discountMoney = item.discount
                this.examInfo.classid = item.classid
              }
            })
          }
          if(this.newInfo != '') {
            this.examInfo = this.newInfo
          }
        }
      })
    },
    changerList(){
      this.$forceUpdate()
    }
  },
  watch:{
    // active:{
    //   deep:true,
    //   immediate:true,
    //   handler(val){
    //     if(val == 2) {
    //       this.getExamList()
    //     }
    //   }
    // },
    "examInfo.examid":{
      deep:true,
      immediate:true,
      handler(val) {
        if(!this.$f.isNotNull(val)) return false
        this.getUSerDiscount()
        this.examList.filter(item => {
          if(item.id == this.examInfo.examid) {
            this.examMoney = item.money
          }
        })
      }
    },
    dialogVisible:{
      deep:true,
      immediate:true,
      handler(val) {
        if(!val) {
          this.examDiscountList = []
          this.examList = []
          this.examMoney = 0
          this.discountMoney = 0
          this.examInfo = {
            id:"",
            name:"",
            userID:this.$f.uid(),
            sex:"1",
            phone:'',
            education:'',
            occupation:'',
            province:'',  // 省
            city:"",  // 市
            area:"",  // 区
            address:"", // 详细地址
            examid:'',
            quserid:'',
            classid:'',
            englishName:'',
             email:"", // 邮箱

          },
          this.newInfo = ''
        } else {
          this.getUserInfo()
          this.getExamList()
        }
      }
    }
  }
}
</script>


<style scoped>
.distpicker-address-wrapper {
  text-align: left;
}
.distpicker-address-wrapper >>> select {
  padding: 0;
  height: 30px;
}
</style>