<template>
  <div>
    <el-dialog
      title="准考证"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="30%"
    >
    <div class="p-l-20 f-s-16">
      <el-row class="text-l">
        <div style="width:50%" class="f-l-l">
           <div >
              姓名:{{ticketInfo.name}}
            </div>
            <div class="m-t-10">
              考试科目:{{ticketInfo.examName}}
            </div>
            <!-- <div class="m-t-10">
              考试时间:{{ticketInfo.examDate}}
            </div> -->
            <div class="m-t-10">
              用户名:{{ticketInfo.uname}}<el-button type="text" v-clipboard="ticketInfo.uname" v-clipboard:success="onCopy"
                  v-clipboard:error="onError">复制</el-button>
            </div>
            <div class="m-t-10">
              密码:{{ticketInfo.password}}<el-button type="text" v-clipboard="ticketInfo.password" v-clipboard:success="onCopy"
                  v-clipboard:error="onError">复制</el-button>
            </div>
        </div>
        <div style="width:49" class="f-l-l">
          <el-image :src="$store.state.overall.user.headimgurl" style="width:120px;height:120px"></el-image>
          <p style="margin:0">{{$store.state.overall.user.uname}}</p>
        </div>
       
      </el-row>
      <div class="text-l m-t-10">
        <div>
          准考证号:{{ticketInfo.admissionNumber}}<el-button type="text" v-clipboard="ticketInfo.admissionNumber" v-clipboard:success="onCopy"
                  v-clipboard:error="onError">复制</el-button>
        </div>
        <div class="m-t-10">
          网址:{{ticketInfo.address}}
          <el-button type="text" @click="openAddress">打开网址</el-button>
        </div>
      </div>
      <el-row class="text-l m-t-10">
        <el-col :span="8">
          成绩:{{ticketInfo.fraction}}
        </el-col>
        <el-col :span="8">
          是否合格:{{ticketInfo.quaName}}
        </el-col>
        <el-col :span="8">
          快递单号:{{ticketInfo.express}}
        </el-col>
      </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible:false,
      ticketInfo:{

      }
    };
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
    },
    openAddress(){
      window.open(this.ticketInfo.address)
    },
    onCopy() {
      this.$message.success("已复制到剪切板");
    },
    onError() {
      this.$message.error("复制失败");
    }
  }
};
</script>

<style scoped>
</style>