<template>
  <div>
    <el-dialog
      title="选择考试"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form class="text-c">
        <el-form-item label="选择考试" label-width="100px">
          <el-select v-model="seleInfoId" placeholder="请选择一级考试">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, i) in examFirstList"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFirstId"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    examFirstList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      seleInfoId: "",
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    saveFirstId(){
      if(this.seleInfoId == "") {
        this.$message.error("请选择考试")
        return false
      }
      this.handleClose()
      this.$emit("editSeleExamFirstId",this.seleInfoId)
      this.seleInfoId = ""
    }
  },
};
</script>

<style scoped>
</style>