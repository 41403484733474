<template>
  <div>
    <p class="title text-l f-s-28">课程目录</p>
    <el-collapse v-model="activeName" accordion class="text-l">
      <el-collapse-item
        v-for="(item, p) in CourseCatalogueList[0]"
        :key="p + '-only'"
        :title="
          (p += 1) + '、' + item.gname + '（共' + item.vlist.length + '小节）'
        "
        :name="p"
      >
        <el-row
          v-for="(pojo, index) in item.vlist"
          :key="index"
          @click.native="lookVideo(pojo, item)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="pojo.vname"
            placement="left"
            :disabled="disabled"
          >
            <el-col class="b-b-1 cu p-b-10 p-t-10">
              <span class="m-l-10">{{ p }}-{{ (index += 1) }}.</span>
              <span class="m-l-10">{{ pojo.vname }}</span>
              <span class="f-l-r m-r-10"
                ><i class="el-icon-video-play m-r-10"></i
                >{{ pojo.timelength }}</span
              >
            </el-col>
          </el-tooltip>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <Notice ref="notice" :cid="cid" />
  </div>
</template>

<script>
import Notice from "@/components/learningCenter/class/item/notice";

export default {
  components: {
    Notice,
  },
  props: {
    CourseCatalogueList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    cid: String,
    classDetial: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeName: "1",
      disabled: true,
    };
  },
  methods: {
    lookVideo(item, pojo) {
      if (
        this.classDetial.ifBuyThisCourse != undefined &&
        !this.classDetial.ifBuyThisCourse
      ) {
        this.$message.error("请先购买课程");
        return false;
      }
      let data = {
        cid: item.cid,
        uid: this.$f.uid(),
      };
      this.Api.Agreement.userIfSignAgreement(data)
        .then((res) => {
          if (res.retuCode == 5028 || res.retuCode == 5025) {
            // 用户未签署协议
            this.$refs.notice.dialogVisible = true;
          } else {
            let { href } = this.$router.resolve({
              path: "/player",
              query: { vid: item.vid, cid: this.cid, gid: pojo.gid },
            });
            window.open(href, "_blank");
          }
        })
        .catch(() => {
          let { href } = this.$router.resolve({
            path: "/player",
            query: { vid: item.vid, cid: this.cid, gid: pojo.gid },
          });
          window.open(href, "_blank");
        });
    },
  },
};
</script>

<style scoped>
.el-collapse-item__content {
  padding-bottom: 0;
}
.title {
  border-left: 4px solid #f08040;
  padding-left: 10px;
}
.title {
  border-left: 4px solid #f08040;
  padding-left: 10px;
}
</style>