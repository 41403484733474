import { render, staticRenderFns } from "./addExam.vue?vue&type=template&id=6ac1a766&scoped=true&"
import script from "./addExam.vue?vue&type=script&lang=js&"
export * from "./addExam.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ac1a766",
  null
  
)

export default component.exports