<template>
    <div>
      <el-card v-if="examGroupList.length > 0">
        <el-row>
          <el-col :span="examGroupList.length == 1 ? 24 : 12" v-for="(item,i) in examGroupList" :key="i">
            <el-image :src="item.groupurl" style="width:120px;height:120px"></el-image>
            <p>{{item.groupnum}}</p>
          </el-col>
          <el-col :span="12" v-show="examDetial.houseurl != undefined">
            <el-image :src="examDetial.houseurl" style="width:120px;height:120px"></el-image>
            <p>{{examDetial.housename}}</p>
          </el-col>
        </el-row>
      </el-card>
    </div>
</template>

<script>
export default {
  props:{
    examId:{
      type:Number,
      default:0
    }
  },
  data(){
    return {
      examGroupList:[],
      examDetial:{
        houseurl:'',
        housename:''
      }
    }
  },
  methods:{
    getExamGroup(){
      let data = {
        examid:this.examId
      }
      this.Api.Exam.getExamGroupList(data).then(res => {
        if(res.retuCode == 200) {
          this.examGroupList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getExamDetial(){
      let data = {
        id : this.examId
      }
      this.Api.Exam.getExamDetial(data).then(res => {
        if(res.retuCode == 200) {
          this.examDetial = res.data
        }else {
          this.$message.error(res.message)
        }
      })
    }
  },
  watch:{
    examId:{
      deep:true,
      immediate:true,
      handler(val){
        if(val == 0) return false
        this.getExamGroup()
        this.getExamDetial()
      }
    }
  }
}
</script>

<style scoped>

</style>