<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
      style="min-width:1230px"
      :before-close="handleClose"
    >
      <div>
        <el-row>
           <el-col :sm="24" :lg="24">
              <el-result icon="info" title="「温馨提示：非正式学员请先购买课程！」" subTitle="该考试报名只针对付费学员">
              </el-result>
            </el-col>
        </el-row>
        <Curriculum  :classType="classType"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Curriculum from '@/views/curriculum/curriculum'
export default {
  data() {
    return {
      dialogVisible:false,
      classType:'1'
    };
  },
  components:{
    Curriculum
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
    }
  }
};
</script>

<style scoped>
</style>