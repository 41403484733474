<template>
  <div>
    <el-row class="text-l" v-if="list.length > 0">
      <el-col :span="24" class="b m-t-10" style="border-radius: 10px" v-for="(item,index) in list" :key="index" @click.native="getCLassDetial(item)">
        <p class="title" style="margin:0">BIM</p>
        <p class="p-r-10 p-l-10 cu">
          {{item.ctitle}}
          <span class="f-l-r">课程有效期： {{item.paymentDate}} 至 {{item.endDate}} </span>
        </p>
      </el-col>
    </el-row>
    <div v-else @click="goClass" style="margin-top:10%">
      <el-image :src="noClass" style="width:25%"></el-image>
      <el-button type="warning" style="display:block;margin-left:45%">去选课</el-button>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      style="overflow:auto"
      >
      <CourseCatalogue  :CourseCatalogueList="CourseCatalogueList" :cid="cid" class="p-l-10 p-r-10 yichu" />
    </el-drawer>

    <Notice ref="notice" :cid="cid" />
  </div>
</template>
<script>
import CourseCatalogue from "@/components/curriculum/item/courseCatalogue";
import Notice from "@/components/learningCenter/class/item/notice";
export default {
  components:{
    CourseCatalogue,Notice
  },
  props:{
    list:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  data() {
    return {
      drawer:false,
      direction: 'rtl',
      CourseCatalogueList:[],
      cid:0,
      noClass:require("@/assets/img/class/noClass.png")
    };
  },
  methods:{
    goClass(){
      this.$router.push("/curriculum")
    },
    getCLassDetial(item){
      let data = {
        cid : item.cid,
        uid : this.$f.uid()
      }
      this.cid = data.cid
      const newWindow = window.open("","_blank")
      this.Api.Agreement.userIfSignAgreement(data).then(res => {
        if(res.retuCode == 5028 || res.retuCode == 5025){  // 用户未签署协议
          newWindow.close()
          this.$refs.notice.dialogVisible = true
        }  else {
          let { href } = this.$router.resolve({
            path: "/player",
            query: { cid: item.cid },
          });
          newWindow.location = href
          // window.open(href, "_blank");
        }
      }).catch(() => {
        let { href } = this.$router.resolve({
          path: "/player",
          query: { cid: item.cid },
        });
        window.open(href, "_blank");
      })
    },
    handleClose(){
      this.drawer = false
    }
  }
};
</script>


<style scoped>
.title {
  background: rgba(172, 172, 172, 0.1);
  color: rgba(54, 54, 54, 1);
  padding-left: 20px;
  height: 38px;
  line-height: 38px;
}
</style>

<style>
.el-drawer.rtl{
  overflow: auto;
}
</style>