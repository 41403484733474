<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      top="1vh"
    >
      <el-row class="text-l">
        <p class="f-s-24 b-l-4B t-i-4">商品信息</p>
        <el-table :data="dityList" border style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scoped">
              <p @click="orderDetial(scoped.row)">
                {{ scoped.row.name }}
                <el-link type="primary">{{
                  scoped.row.state == "2" ? "去看课" : "查看详情"
                }}</el-link>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量"> </el-table-column>
          <el-table-column prop="price" label="价格"> </el-table-column>
        </el-table>
        <p class="f-s-24 b-l-4B t-i-4">订单信息</p>
        <el-table :data="orderList" border style="width: 100%">
          <el-table-column prop="orderNumber" label="订单编号" width="240px">
          </el-table-column>
          <el-table-column prop="orderTime" label="下单时间"> </el-table-column>
          <el-table-column prop="paymenttime" label="付款时间">
          </el-table-column>
          <el-table-column prop="paymentway" label="付款方式">
          </el-table-column>
        </el-table>
        <p class="f-s-24 b-l-4B t-i-4">结算金额</p>
        <el-table :data="mentList" border style="width: 100%">
          <el-table-column prop="coursePrice" label="订单金额">
          </el-table-column>
          <el-table-column prop="coupon" label="优惠金额"> </el-table-column>
          <el-table-column prop="practicalPrice" label="实付金额">
          </el-table-column>
        </el-table>
        <div v-show="payType">
          <TitleText text="扫码支付(支付过程中如遇到问题请联系伴学老师)" />
          <Mode ref="mode" @editPayRadio="editPayRadio" />
          <WxPay ref="wxpay" v-show="payRadio == '1'" :icon="wxPayPic" />
          <AliPay ref="alipay" v-show="payRadio == '2'" :icon="aliPayPic" />
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          @click="payment"
          type="success"
          v-show="orderData.state != '2'"
          >{{ payText() }}</el-button
        >
      </span>
    </el-dialog>
    <Notice ref="notice" :cid="cid" />
  </div>
</template>

<script>
import TitleText from "@/components/pay/titleText";
import Mode from "@/components/pay/mode";
import Notice from "@/components/learningCenter/class/item/notice";

export default {
  components: {
    TitleText,
    Mode,
    Notice,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    orderData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      dityList: [], // 商品信息
      orderList: [], // 订单信息
      mentList: [], // 结算金额
      payRadio: "1",
      wxUrl: "",
      aliUrl: "",
      payType: false,
      wxPayPic: require("@/assets/img/pay/wxPayPic.jpeg"),
      aliPayPic: require("@/assets/img/pay/ali.jpeg"),
      cid:''
    };
  },
  methods: {
    // 付款
    payment(c) {
      if (this.payType && c == undefined) {
        this.payLook();
        return false;
      }
      if (this.orderData.state == "3") {
        this.$confirm("订单已失效,请重新购买", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/course/toOneCenter",
              query: { cid: this.orderData.cid },
            });
          })
          .catch(() => {});
        return;
      }
      let type = this.payRadio;
      if (type == "1" && this.wxUrl != "") {
        return;
      } else if (type == "2" && this.aliUrl != "") {
        return;
      }
      let data = {
        aid: this.orderData.aid,
        uid: this.$f.uid(),
        cid: this.orderData.cid,
        orderNumber: this.orderData.orderNumber,
        type,
        userCourseId: this.orderData.userCourseId,
      };
      this.Api.Pay.Pay(data).then((res) => {
        this.payType = true;
        if (this.$refs.mode.radio == "1") {
          this.wxUrl = res.data.qRcode;
          this.$refs.wxpay.wxPayUrl = res.data.qRcode;
        } else {
          this.aliUrl = res.data.qRcode;
          this.$refs.alipay.alpayUrl = res.data.qRcode;
        }
      });
    },
    // 是否去付款的文字
    payText() {
      if (this.orderData.state == "3") {
        return "订单失效,请重新购买";
      } else if (this.payType) {
        return "支付完成没有反应请点击";
      } else {
        return "继续付款";
      }
    },
    // 修改父组件选中的支付方式
    editPayRadio(val) {
      this.payRadio = val;
      if (this.payType) this.payment("C");
    },
    // 订单的详情
    orderDetial(item) {
      if (item.state == "2") {
        //  2 已付款  1 待付款  3 已失效
        let data = {
          cid: item.cid,
          uid: this.$f.uid(),
        };
        this.cid = data.cid;
        this.Api.Agreement.userIfSignAgreement(data)
          .then((res) => {
            if (res.retuCode == 5028 || res.retuCode == 5025) {
              // 用户未签署协议
              this.$refs.notice.dialogVisible = true;
            } else {
              let { href } = this.$router.resolve({
                path: "/player",
                query: { cid: item.cid },
              });
              window.open(href, "_blank");
            }
          })
          .catch(() => {
            let { href } = this.$router.resolve({
              path: "/player",
              query: { cid: item.cid },
            });
            window.open(href, "_blank");
          });
        // this.$router.push({
        //   path: "/player",
        //   query: {
        //     cid: item.cid,
        //   },
        // });
      } else {
        this.$router.push({
          path: "/course/toOneCenter",
          query: { cid: item.cid },
        });
      }
    },
    handleClose() {
      this.dityList = [];
      this.orderList = [];
      this.mentList = [];
      this.dialogVisible = false;
      this.payType = false;
    },
    payLook() {
      if (!this.dialogVisible) return false;
      let data = {
        orderNumber: this.orderList[0].orderNumber,
      };
      this.Api.Pay.payResult(data).then((res) => {
        if (res.status == 200) {
          let payInfo = res.data;
          if (payInfo.state == 1) {
            setTimeout(() => {
              this.payLook();
            }, 2000);
            return false;
          }
          let payTitle = payInfo.state == 2 ? "支付成功" : "支付失败";
          let payType = payInfo.state == 2 ? "success" : "warning";
          this.$emit("paySuc");
          this.$confirm(payTitle, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: payType,
          })
            .then(() => {
              if (payInfo.state == 2) {
                this.$router.push({
                  path: "/player",
                  query: {
                    vid: null,
                    cid: this.order.cid,
                  },
                });
                // this.$message.success("去详情");
              } else {
                this.payLook();
              }
            })
            .catch(() => {
              this.dialogVisible = false;
            });
        }
      });
    },
  },
  watch: {
    orderData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!this.$f.isNotNull(val)) return;
        let dity = {
          name: val.ctitle,
          num: 1,
          price: val.discountPrice,
          state: val.state,
          cid: val.cid,
        };
        this.dityList.push(dity);
        let order = {
          orderNumber: val.orderNumber,
          orderTime: val.orderDate,
          paymenttime: val.paymentDate,
          paymentway: val.paymentway,
        };
        this.orderList.push(order);
        let ment = {
          coursePrice: val.coursePrice,
          coupon: val.aid == 0 ? 0 : val.coupon,
          practicalPrice: val.practicalPrice,
        };
        this.mentList.push(ment);
      },
    },
    open: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) this.dialogVisible = true;
      },
    },
    dialogVisible: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != this.open) {
          this.$emit("editOpen", val);
        }
      },
    },
    payType: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) this.payLook();
      },
    },
  },
};
</script>

<style scoped>
</style>