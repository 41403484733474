<template>
  <div class="xz-bgPlayColor p-t-30 p-b-20" >
    <el-row style="min-height:575px;">
      <div class="leftTab xz-b">
        <el-tabs type="border-card" tab-position="left" v-model="tabsActive" :before-leave="TabItem" style="min-height:600px">
          <el-tab-pane name="1">
            <span slot="label"><i class="el-icon-date"></i> 我的课程</span>
            <Class />
          </el-tab-pane>
          <el-tab-pane name="2">
            <span slot="label"><i class="el-icon-bank-card"></i> 我的订单</span>
            <Order />
          </el-tab-pane>
          <el-tab-pane name="3">
            <span slot="label" class="f-l-l"><i class="el-icon-goods"></i> 优惠券</span>
            <Coupon />
          </el-tab-pane>
          <el-tab-pane name="4">
            <span slot="label" class="f-l-l"><i class="el-icon-user"></i> 账户设置</span>
            <Setting />
          </el-tab-pane>
          <el-tab-pane name="5">
            <span slot="label" class="f-l-l"><i class="el-icon-s-order"></i> 协议列表</span>
            <AgreementList />
          </el-tab-pane>
          <el-tab-pane name="6">
            <span slot="label" class="f-l-l"><i class="el-icon-tickets"></i> 考试报名</span>
            <ExamList />
          </el-tab-pane>
        </el-tabs>
      </div>  
    </el-row>
    
  </div>
</template>

<script>
import Class from '@/components/learningCenter/class/class'
import Coupon from '@/components/learningCenter/coupon/coupon'
import Order from '@/components/learningCenter/order/order'
import Setting from '@/components/learningCenter/setting/setting'
import AgreementList from '@/components/learningCenter/agreement/agreementList'
import ExamList from "@/components/learningCenter/exam/examList";
export default {
  components:{
    Class,Coupon,Order,Setting,AgreementList,ExamList
  },
  data() {
    return {
      tabsActive:"1",
    };
  },
  methods:{
    TabItem(activeName,oldName){
      if(activeName == 4){
        this.tabsActive = oldName
      }
    },
  },
  computed:{
    tabActive(){
      return this.$store.state.learn.tabActive
    }
  },
  watch:{
    tabActive:{
      deep:true,
      immediate:true,
      handler(val){
        this.$nextTick(() => {
          this.tabsActive = val + ""
        })
      }
    }
  }
};
</script>

<style>
.leftTab .el-tabs__item{
  height: 80px;
  line-height: 80px;
}
.leftTab .el-tabs__nav-wrap::after {
  background: white !important;
}
.leftTab .el-tabs__active-bar{
  background: white;
}
.leftTab .is-active {
  color: rgba(240, 129, 65, 1) !important;
}
.leftTab .el-tabs__item:hover {
  color: black !important;
}
</style>